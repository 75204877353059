import { useNavigate } from "react-router"
// import { subscriptionPayment } from "../../../Network/ServiceClass/dashboardService"
import AppLayout from "../../Widgets/AppLayout"
import { BsCheck } from 'react-icons/bs'
import { useDispatch } from "react-redux"
import { useEffect } from "react"
import { subscriptionThunk } from "../../../Store/Company"


export const SubscriptionScreen = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(()=>{
    dispatch(subscriptionThunk())
  },[])

  const handleOpenStripe = async (amount, plan) => {
    navigate("/subscriptions-detail", {
      state: {
        amount,
        plan
      }
    })
  }


  return (
    <section>
      <AppLayout
        custom="subscriptions"
        subCustom=""
        title="Manage Subscription Plan"
        customTitle=""
        subtitle="Upgrade your productivity with our subscription plan."
        RightComponent={<div className="flex items-center justify-center gap-3">
          <button className="bg-D57 text-white rounded-full font-jakarta text-xs font-bold p-2 px-3">Monthly</button>
          <button className="text-A6A bg-494 font-semibold rounded-full font-jakarta p-2 px-3 text-xs">Yearly</button>
        </div>}
      >
        <div className=" grid grid-cols-4 gap-2 mb-40">
          <div className="p-3 shadow-cardBox pb-6 rounded-md">
            <p className="text-lg font-semibold">Basic</p>
            <p className="text-[10px] text-060 mb-4">Ideal for small businesses and startups</p>
            <div className="flex items-center gap-1">
              <div className="w-2 h-2 rounded-full bg-D57" />
              <p className="text-[10px] text-676">Up to <span className="text-D57 font-semibold">10 users</span> allowed</p>
            </div>
            <div className="flex items-center gap-1">
              <div className="w-2 h-2 rounded-full bg-D57" />
              <p className="text-[10px] text-676">Up to <span className="text-D57 font-semibold">3 job roles</span> allowed</p>
            </div>
            <div className="flex items-center gap-1">
              <div className="w-2 h-2 rounded-full bg-D57" />
              <p className="text-[10px] text-676">Up to <span className="text-D57 font-semibold">1 location</span> allowed</p>
            </div>
            <p className="mt-7 text-xl font-bold">Free</p>
            <button className=" border-[0.5px] border-E42 font-bold rounded-md h-[50px] w-full text-E42 my-6">Manage Plan</button>
            <div className="h-[1px] bg-3F0 w-full mb-5" />
            <p className="font-bold text-base">Features</p>
            <div className="flex items-center gap-1">
              <div className="w-2 h-2 flex justify-center items-center rounded-full bg-D57"><BsCheck className="text-white font-bold" /></div>
              <p className="text-[10px] text-676">Basic shift scheduling</p>
            </div>
            <div className="flex items-center gap-1">
              <div className="w-2 h-2 flex justify-center items-center rounded-full bg-D57"><BsCheck className="text-white font-bold" /></div>
              <p className="text-[10px] text-676">Easy communication</p>
            </div>
            <div className="flex items-center gap-1">
              <div className="w-2 h-2 flex justify-center items-center rounded-full bg-D57"><BsCheck className="text-white font-bold" /></div>
              <p className="text-[10px] text-676">Create & assign tasks</p>
            </div>
          </div>

          <div className="p-3 shadow-cardBox pb-6 rounded-md">
            <p className="text-lg font-semibold">Starter Plan</p>
            <p className="text-[10px] text-060 mb-4">Perfect for growing business</p>
            <div className="flex items-center gap-1">
              <div className="w-2 h-2 rounded-full bg-D57" />
              <p className="text-[10px] text-676">Up to <span className="text-D57 font-semibold">25 users</span> allowed</p>
            </div>
            <div className="flex items-center gap-1">
              <div className="w-2 h-2 rounded-full bg-D57" />
              <p className="text-[10px] text-676">Up to <span className="text-D57 font-semibold">5 job roles</span> allowed</p>
            </div>
            <div className="flex items-center gap-1">
              <div className="w-2 h-2 rounded-full bg-D57" />
              <p className="text-[10px] text-676">Up to <span className="text-D57 font-semibold">3 location2</span> allowed</p>
            </div>
            <p className="mt-7 text-xl font-bold">$29<span className="text-[10px] font-normal text-060">per month</span></p>
            <button onClick={() => handleOpenStripe(29, "Starter Plan")} className=" border-[0.5px] border-E42 font-bold rounded-md h-[50px] w-full text-white bg-E42 my-6">Upgrade</button>
            <div className="h-[1px] bg-3F0 w-full mb-5" />
            <p className="font-bold text-base">Features</p>
            <div className="flex items-center gap-1">
              <div className="w-2 h-2 flex justify-center items-center rounded-full bg-D57"><BsCheck className="text-white font-bold" /></div>
              <p className="text-[10px] text-676">Basic shift scheduling</p>
            </div>
            <div className="flex items-center gap-1">
              <div className="w-2 h-2 flex justify-center items-center rounded-full bg-D57"><BsCheck className="text-white font-bold" /></div>
              <p className="text-[10px] text-676">Easy communication</p>
            </div>
            <div className="flex items-center gap-1">
              <div className="w-2 h-2 flex justify-center items-center rounded-full bg-D57"><BsCheck className="text-white font-bold" /></div>
              <p className="text-[10px] text-676">Create & assign tasks</p>
            </div>
          </div>

          <div className="p-3 shadow-cardBox pb-6 rounded-md">
            <p className="text-lg font-semibold">Professional Plan</p>
            <p className="text-[10px] text-060 mb-4">Designed for  larger enterprises</p>
            <div className="flex items-center gap-1">
              <div className="w-2 h-2 rounded-full bg-D57" />
              <p className="text-[10px] text-676">Up to <span className="text-D57 font-semibold">50 users</span> allowed</p>
            </div>
            <div className="flex items-center gap-1">
              <div className="w-2 h-2 rounded-full bg-D57" />
              <p className="text-[10px] text-676">Up to <span className="text-D57 font-semibold">10 job roles</span> allowed</p>
            </div>
            <div className="flex items-center gap-1">
              <div className="w-2 h-2 rounded-full bg-D57" />
              <p className="text-[10px] text-676">Up to <span className="text-D57 font-semibold">5 locations</span> allowed</p>
            </div>
            <p className="mt-7 text-xl font-bold">$79<span className="text-[10px] font-normal text-060">per month</span></p>
            <button onClick={() => handleOpenStripe(79, "Professional Plan")} className=" border-[0.5px] border-E42 font-bold rounded-md h-[50px] w-full text-white bg-E42 my-6">Upgrade</button>
            <div className="h-[1px] bg-3F0 w-full mb-5" />
            <p className="font-bold text-base">Features</p>
            <div className="flex items-center gap-1">
              <div className="w-2 h-2 flex justify-center items-center rounded-full bg-D57"><BsCheck className="text-white font-bold" /></div>
              <p className="text-[10px] text-676">Advanced shift scheduling</p>
            </div>
            <div className="flex items-center gap-1">
              <div className="w-2 h-2 flex justify-center items-center rounded-full bg-D57"><BsCheck className="text-white font-bold" /></div>
              <p className="text-[10px] text-676">AI chat bot</p>
            </div>
            <div className="flex items-center gap-1">
              <div className="w-2 h-2 flex justify-center items-center rounded-full bg-D57"><BsCheck className="text-white font-bold" /></div>
              <p className="text-[10px] text-676">Basic PTO tracking</p>
            </div>
            <div className="flex items-center gap-1">
              <div className="w-2 h-2 flex justify-center items-center rounded-full bg-D57"><BsCheck className="text-white font-bold" /></div>
              <p className="text-[10px] text-676">Data visualization</p>
            </div>
          </div>

          <div className="p-3 shadow-cardBox pb-6 rounded-md">
            <p className="text-lg font-semibold">Custom Plan</p>
            <p className="text-[10px] text-060 mb-4">Run your company on your terms</p>
            <div className="flex items-center gap-1">
              <div className="w-2 h-2 rounded-full bg-D57" />
              <p className="text-[10px] text-676">Custom users allowed</p>
            </div>
            <div className="flex items-center gap-1">
              <div className="w-2 h-2 rounded-full bg-D57" />
              <p className="text-[10px] text-676">Custom job roles allowed</p>
            </div>
            <div className="flex items-center gap-1">
              <div className="w-2 h-2 rounded-full bg-D57" />
              <p className="text-[10px] text-676">Custom job roles allowed</p>
            </div>
            <p className="mt-7 text-xl font-bold">--<span className="text-[10px] font-normal text-060"> based on requirement</span></p>
            <button className=" border-[0.5px] border-secondary font-bold rounded-md h-[50px] w-full text-secondary my-6">Contact us</button>
            <div className="h-[1px] bg-3F0 w-full mb-5" />
            <p className="font-bold text-base">Features</p>
            <div className="flex items-center gap-1">
              <div className="w-2 h-2 flex justify-center items-center rounded-full bg-D57"><BsCheck className="text-white font-bold" /></div>
              <p className="text-[10px] text-676">Tailored to specific needs</p>
            </div>
            <div className="flex items-center gap-1">
              <div className="w-2 h-2 flex justify-center items-center rounded-full bg-D57"><BsCheck className="text-white font-bold" /></div>
              <p className="text-[10px] text-676">Personalized pricing</p>
            </div>
            <div className="flex items-center gap-1">
              <div className="w-2 h-2 flex justify-center items-center rounded-full bg-D57"><BsCheck className="text-white font-bold" /></div>
              <p className="text-[10px] text-676">Support and implementation</p>
            </div>
          </div>


        </div>
      </AppLayout>
    </section>
  )
}